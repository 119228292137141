import { NavLink } from 'react-router-dom';
import PageContent from './components/PageContent';
import PageHeader from './components/PageHeader';
import React from 'react';
import { SocialIcon } from 'react-social-icons';
import { connect } from "react-redux";
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

const mapStateToProps = state => {
  return {
    cv: state.cv?.cv
  };
};

function Home(props) {
  return (
    <React.Suspense fallback='Loading gallery...'>
      <PageHeader color="is-info" title="About me">
          <strong>Thomas Spanner</strong>'s personal home page.
      </PageHeader>
      <PageContent>
        <div className="box cv">
          <p>I'm a fullstack software developer and my passion is traveling. Here's my&nbsp;
            <NavLink to="/blog">blog</NavLink>!
          </p>
          <p>I also enjoy listening to podcasts, long-distance-running and&nbsp;
            <NavLink to="/photography">photography</NavLink>.
          </p>
          <br />
          <div className="field is-horizontal">
            <span className="field-label">
                <label className="label">Name</label>
            </span>
            <span className="field-body">
              <span className="field">
                {props.cv?.fields?.name}
              </span>
            </span>
          </div>
          <div className="field is-horizontal">
            <span className="field-label">
                <label className="label">Birthdate</label>
            </span>
            <span className="field-body">
              <span className="field">
                {props.cv?.fields?.birthday}
              </span>
            </span>
          </div>
          <div className="field is-horizontal">
            <span className="field-label">
              <label className="label">Address</label>
            </span>
            <span className="field-body">
              <span className="field">
                {props.cv?.fields?.address}
              </span>
            </span>
          </div>
          <div className="field is-horizontal">
            <span className="field-label">
              <label className="label">Education</label>
            </span>
            <span className="field-body">
              <span className="field">
                {documentToReactComponents(props.cv?.fields?.education)}
              </span>
            </span>
          </div>
          <div className="field is-horizontal">
            <span className="field-label">
              <label className="label">Work experience</label>
            </span>
            <span className="field-body">
              <span className="field">
                {documentToReactComponents(props.cv?.fields?.jobs)}
              </span>
            </span>
          </div>
          <div className="field is-horizontal">
            <span className="field-label">
              <label className="label">Skills</label>
            </span>
            <span className="field-body">
              <span className="field">
                {documentToReactComponents(props.cv?.fields?.skills)}
              </span>
            </span>
          </div>
          <div className="field is-horizontal">
            <span className="field-label">
              <label className="label">Contact me</label>
            </span>
            <span className="field-body">
              <span className="field">
                <SocialIcon target="_blank" url="https://www.linkedin.com/in/thomas-spanner-625ba61b4" />
                <SocialIcon target="_blank" url="https://www.xing.com/profile/Thomas_Spanner3" />
                <SocialIcon target="_blank" url="https://github.com/Malaclypse23" />
                <SocialIcon target="_blank" url="https://twitter.com/TomDoom" />
                <SocialIcon target="_blank" url="mailto:info@thomasspanner.de" />
              </span>
            </span>
          </div>
        </div>
      </PageContent>
    </React.Suspense>
  )
}
  
export default connect(mapStateToProps)(Home);